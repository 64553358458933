<template>
<div></div>
</template>

<script>
export default {
    mounted() {
        this.$store.commit('setOnbehalfDialog', true)
    }
}
</script>
